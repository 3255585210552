import React, { useRef } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { RouteComponentProps } from 'react-router'

import 'mapbox-gl/dist/mapbox-gl.css'

import Map from '../../components/extra/Map'
import FooterMenu from '../../components/common/FooterMenu'
//import TermsPrivacy from '../../components/common/TermsPrivacy'

const mapStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '100%',
  height: '110%',
}

interface PageProps extends RouteComponentProps<{ selected: string }> { }

const MyMap: React.FC<PageProps> = ({ match }) => {
  
  const pageRef = useRef<HTMLElement>(null)

  return <IonPage ref={pageRef} key='mapPage' className='no-scroll'>
    {/*<TermsPrivacy />*/}
    <IonContent className='no-scroll'>
      <Map
        urlSelected={match.params.selected}
        geolocation={true}
        showSelector={true}
        style={mapStyle}
        pageRef={pageRef.current}
        scroll={false}        
      />
    </IonContent>
    <FooterMenu index={2}/>
  </IonPage>

}

export default React.memo(MyMap)
