import React, { useState, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet, IonTabBar, IonTabs } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { accessHref } from './env'
import './AppStyles'

/* My functions */
import { storingAppData } from '../src/data/utils/data'

/* My pages */
import Map from '../src/pages/LiveMap'
import Routes from '../src/pages/Routes'
import Access from '../src/pages/Access'
import Menu from '../src/pages/Menu'

/* Extra pages */
import Article from '../src/components/extra/Article'
import Overview from '../src/components/extra/Overview'
import Equipment from '../src/components/extra/Equipment'
import SplashScreen from './components/extra/SplashScreen'
import { homeHref } from './env'

const App: React.FC = () => {

  const [loading, setLoading] = useState<boolean>(true)
  
  useEffect(() => {
    storingAppData()
      .then(() => setLoading(false))
      .catch((err) => console.error(err))
  }, [setLoading])

  return <>{loading
    ? <SplashScreen />
    : <IonApp className='background fade-in-2 soft-grey'>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route path='/' render={() => <Redirect to={accessHref} />} exact={true} />
              <Route path='/menu' render={() => <Redirect to={accessHref} />} exact={true} />
              <Route path='/home' render={() => <Redirect to={homeHref} />} exact={true} />
              <Route path='/map' render={() => <Redirect to='/map/navigate/0' />} exact={true} />
              <Route path='/map/navigate' render={() => <Redirect to='/map/navigate/0' />} exact={true} />
              <Route path='/article' render={() => <Redirect to='/article/the-boat/0' />} exact={true} />
              <Route path='/access/' component={Access} />
              <Route path='/access/:identifier/:password' component={Access} />
              <Route path='/menu/:slug' component={Menu} />
              <Route path='/map/navigate/:selected' component={Map} />
              <Route path='/route/overview/:route/:step' component={Overview} />
              <Route path='/routes' component={Routes} />
              <Route path='/article/:slug' component={Article} />
              <Route path='/article/:slug/:step' component={Article} />
              <Route path='/equipment/:boat' component={Equipment} />
              <Route path='/equipment' component={Equipment} />
            </IonRouterOutlet>
            <IonTabBar slot='bottom' style={{ display: 'none' }}>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </IonApp>
  }</>

}

export default React.memo(App)