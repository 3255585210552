
import { Preferences } from '@capacitor/preferences'
import { apiUrl } from '../../env'
import axios from 'axios'

const toStringify = (value: any) => {
  return getType(value) === 'object' || getType(value) === 'array'
}

export const setStorage = async (key: string, value: any) => {
  let res = toStringify(value) ? JSON.stringify(value) : value.toString()
  await Preferences.set({ key: key, value: res })
}

export const getStorage = async (key: string) => {
  let { value } = await Preferences.get({ key: key })
  //console.log(getType(value), value)
  return value
    ? (getType(value) === 'string' 
      ? JSON.parse(value)
      : value)
    : null
}

export const removeStorage = async (key: string) => {
  await Preferences.remove({ key: key })
}

export const clearStorage = async () => {
  await Preferences.clear()
}

export const switchStorage = (key: string, value: any) => {
  if (value) {
    setStorage(key, value)
  } else {
    removeStorage(key)
  }
}

const getType = (p: any) => {
  if (Array.isArray(p)) return 'array'
  else if (typeof p == 'string') return 'string'
  else if (p != null && typeof p == 'object') return 'object'
  else return 'other'
}

export const storeImageByUri = async (uri: string) => {
  await axios.get(uri)
    .then(async (data: any) => {

      let b = btoa(unescape(encodeURIComponent(data.data)))
      let baseImage = 'data:' + data.headers['content-type'] + ';base64,' + b
      await setStorage(uri, baseImage)
      /*
      //const response = await fetch( data.data.url)
      const imageBlob = await data.data.blob()
      console.log(imageBlob)
      const reader = new FileReader();
      reader.readAsDataURL(imageBlob);
      reader.onloadend = () => {
        const base64data = reader.result
        console.log(base64data);
        setStorage(uri, base64data)
      }*/

    })
}

export const getBase64 = async (uri: string) => {
  return await axios.get(uri)
    .then(async (data: any) => {

      let b = btoa(unescape(encodeURIComponent(data.data)))
      let baseImage = 'data:' + data.headers['content-type'] + ';base64,' + b
      //await setStorage(uri, baseImage)
      return { url: uri, base64: baseImage }
      /*
      //const response = await fetch( data.data.url)
      const imageBlob = await data.data.blob()
      console.log(imageBlob)
      const reader = new FileReader();
      reader.readAsDataURL(imageBlob);
      reader.onloadend = () => {
        const base64data = reader.result
        console.log(base64data);
        setStorage(uri, base64data)
      }*/

    })
}

export const cachingImages = async (paths: any) => {
  const promises = await paths.map(async (path: string) => {
    return new Promise((resolve: any, reject: any) => {
      let img = new Image()
      img.onload = () => resolve(path)
      img.onerror = () => reject()
      img.src = path
    })
  })
  await Promise.all(promises)
}

export const gsi = (uri: string) => {
  return getStorageImage(uri)
}

const getStorageImage = (uri: string) => {
  if (navigator.onLine) {
    return uri
  } else {
    return getStorage(uri)
  }
}

export const storeApiData = async (key: string, url: string) => {
  await axios.get(apiUrl + '/' + url)
    .then(res => {
      let r = res.data
      setStorage(key, r)
    })
}
