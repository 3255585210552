import React, { useState, useEffect, useMemo, useRef } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { IonPage, IonRow, IonCol, IonButton, getPlatforms, IonInput, useIonLoading, useIonModal, IonSpinner, IonGrid, IonFab, IonFabButton, IonIcon, IonSelect, IonSelectOption, IonLabel } from '@ionic/react'

import { qrCodeOutline } from 'ionicons/icons'
import { object, string } from 'yup'

import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner'
import AddToHomeScreen from '@ideasio/add-to-homescreen-react'

import { ObjectShape } from 'yup'
import { warning } from 'ionicons/icons'
import { useForm } from 'react-hook-form'
import axios from 'axios'

import { ConMiddle, Middle } from './styles'

import { storingClientData, isApprovedUrl } from '../../data/utils/data'
import { MenuProps } from '../../data/models/Menu'
import { addToHomeScreenSettings } from '../../data/static/addToHomeScreen'
import { accessData } from '../../data/static/access'
import { getStorage } from '../../data/utils/storage'

import i18n from '../../components/common/i18n'
import Input from '../../components/common/Input'
import Toast from '../../components/common/Toast'
import Header from '../../components/common/Header'
import { ToastProps } from '../../components/common/Toast/types'
import TermsPrivacy2 from '../../components/common/TermsPrivacy2'

import QRWebModal from '../../components/extra/QR/QRWebModal'
import QRCodeScannedModal from '../../components/extra/QR/QRCodeScannedModal'

import { useStoreState } from 'pullstate'
import { getCodes } from '../../store/Selectors'
import { QRStore } from '../../store'

import NoQRCodes from '../../components/extra/QR/NoQRCodes'
import QRCodeList from '../../components/extra/QR/QRCodeList'
import { useTranslation } from 'react-i18next'
import { translations, homeHref, apiUrl } from '../../env'

interface AccessProps extends RouteComponentProps<{
  identifier?: string,
  password?: string,
}> { }

const Access: React.FC<AccessProps> = ({ match }) => {

  //const [ play ] = useSound(openSound)

  let lang = i18n.language
  const { t } = useTranslation()
  const history = useHistory()

  const pageRef = useRef()
  const codes = useStoreState(QRStore, getCodes)

  const [QRData, setQRData] = useState('')
  const [header, setHeader] = useState<MenuProps>(accessData as any as MenuProps)
  const [sending, setSending] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [toast, setToast] = useState<ToastProps>()
  const [launchLoader, dismissLoader] = useIonLoading()

  const validation = useMemo(()=>{
    return {
      identifier: string().required().email(),
      password: string().required().min(8).max(64)//.matches(/^[A-Za-z]\w{7,14}$/, t('This pass is nos secure!')),
    }
  },[])

  const [formValidation, setFormValidation] = useState<ObjectShape>(validation)
  const validationSchema = object().shape(formValidation)
  const { control, handleSubmit, errors } = useForm({ validationSchema })

  const [present, dismiss] = useIonModal(QRCodeScannedModal, {
    dismiss: () => dismiss(),
    code: QRData,
    set: (v: any) => setQRData(v),
    scan: () => QrRead.start()
  })

  const [presentWebModal, dismissWebModal] = useIonModal(QRWebModal, {
    dismiss: () => dismissWebModal(),
    set: (v: any) => setQRData(v),
    scan: (data: any) => {
      if (data) {
        //play()
        setQRData(data)
        QrRead.handleSuccess(data)
      }
    },
    error: (err: Error) => console.error(err),
  })

  let objAccess = useMemo(() => {
    return {

      timestamp: Date.now(),

      storage: 'menu_access',

      origin: 'app-menus?slug=access',

      load: async () => {
        setHeader(accessData as any as MenuProps)
        getStorage(objAccess.storage)
          .then(async (data) => { await objAccess.success(data) })
          .then(() => {
            getStorage('creator:id')
              .then((creator_id) => {
                if (creator_id) {
                  if (creator_id !== null) {
                    launchLoader(t('Redirecting to Home') ?? 'Redirecting to home', 2800, 'dots')
                    setTimeout(() => history.replace(homeHref), 3000)
                  }
                }
              })
          })
          .catch(objAccess.error)
      },
      success: async (r: any) => setFormValidation(validation as any),
      error: () => {
        history.go(0)
      }

    }
  }, [history, launchLoader, t, validation])

  let access = useMemo(() => {

    return {

      goHome: () => history.replace(homeHref),

      tryLogin: async (inputs: any) => {
        if (inputs.identifier && inputs.password) {
          setSending(true)
          axios.post(apiUrl + '/auth/local', {
            identifier: inputs.identifier,
            password: inputs.password,
          })
            .then(async (res) => {
              if (res.status === 200) {
                setSending(false)
                await access.loginThen(res.data)
              } else {
                access.getCatch(t(translations.invalidCredentials), 'danger')
              }
            })
            .catch(access.axiosCatch)
        } else {
          access.getCatch(t(translations.fillForm), 'danger')
        }
      },

      loginQr: async () => {
        //http://localhost:8100/access/test@me.com/Qwer1234
        //http://localhost:8100/access/training@seashoreboats.com/training.sbm
        if (match.params.identifier !== undefined && match.params.password !== undefined) {
          setSending(true)
          axios.post(apiUrl + '/auth/local', {
            identifier: match.params.identifier,
            password: match.params.password,
          }).then(async (res) => {
            if (res.status === 200) {
              await access.loginThen(res.data)
            } else {
              access.getCatch(t(translations.invalidCredentials), 'danger')
            }
            setSending(false)
          }).catch(access.axiosCatch)
        }
      },

      loginQr2: async (QRData: any) => {
        //http://localhost:8100/access/test@me.com/Qwer1234
        //http://localhost:8100/access/training@seashoreboats.com/training.sbm
        let anal = QrRead.analice(QRData)
        if (anal.identifier !== '' && anal.password !== '') {
          setSending(true)
          axios.post(apiUrl + '/auth/local', {
            identifier: anal.identifier,
            password: anal.password,
          }).then(async (res) => {
            if (res.status === 200) {
              await access.loginThen(res.data)
            } else {
              access.getCatch(t(translations.invalidCredentials), 'danger')
            }
            setSending(false)
          }).catch(access.axiosCatch)
        }
      },

      loginThen: async (userData: any) => {
        if (userData.user !== undefined) {
          setSending(false)
          dismissWebModal()
          launchLoader(t('Login success! ') ?? 'Login success!', 2500, 'dots')
          await storingClientData(userData)
            .then(async (result) => {
              if (result) {
                setTimeout(() => {
                  dismissLoader()
                  access.goHome()
                }, 1500)
              } else {
                dismissLoader()
                console.log('Was a problem, again!!')
              }
            })
        }
      },

      getCatch: (message: string, color?: string, timestamp?: number, duration: number = 3000) => {
        setToast({
          'show': true,
          'message': t(message) ?? 'errorAccessPage',
          'color': color ?? 'warning',
          'duration': duration,
          'type': color ?? 'warning',
          'icon': warning,
          'timestamp': timestamp ?? Date.now()
        })
      },

      axiosCatch: (err: any) => {
        setSending(false)
        if (err.response) {
          access.getCatch(err.response.data.message[0].messages[0].message, 'warning')
        } else if (err.request) {
          console.log(t('The request was made but no response was received'), err.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          access.getCatch(err.message, 'warning')
        }
      },

      changeLanguage: (lang: string) => {
        if (i18n.isInitialized && i18n.language !== lang) {
          let h = header
          h.lang = lang
          setHeader(h)
          i18n.changeLanguage(lang)
          setFormValidation(validation)
        }
      },

      formFields: [
        {
          name: 'identifier',
          label: t(translations.userEmail),
          component: <IonInput className='' type='email' />,
          class: '',
          type: 'email',

        },
        {
          name: 'password',
          label: t(translations.userPassword),
          component: <IonInput type='password' className='' clearOnEdit={false} />,
          class: '',
          type: 'password'
        }
      ],

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, match, QRData])

  let QrRead = useMemo(()=>{

    return {

      handleSuccess: (data: any) => {
        //play()
        setQRData(data)
        present({
          presentingElement: pageRef.current
        })
      },
    
      start: async () => {
    
        const platforms = getPlatforms()
        const isWeb = (platforms.includes('desktop') || platforms.includes('mobileweb') || platforms.includes('pwa'))
    
        if (!isWeb) {
          const data = await BarcodeScanner.scan()
          if (data) {
            QrRead.handleSuccess(data)
          }
        } else {
          presentWebModal({
            presentingElement: pageRef.current
          })
        }
      },
    
      analice: (QRData: any) => {
        return (!!QRData)
          ? isApprovedUrl(QRData, access, t)
          : { identifier: '', password: '' }
      }

    }

  }, [setQRData, access, present, presentWebModal, t])

  useEffect(() => {
    objAccess.load()
      .then(() => access.loginQr())
  }, [match, lang, access, objAccess])

  useEffect(() => {
    access.loginQr2(QRData)
  }, [QRData, access])

  const sendLoginForm: any = async (form: React.FormEvent<Element>) => access.tryLogin(form)

  return <IonPage ref={pageRef} key='login-page' style={{ backgroundColor: 'ivody' }}>
      <>
        <AddToHomeScreen {...addToHomeScreenSettings} />
        {header && <Header header={header as any} />}
        <ConMiddle className='ion-text-center ion-justify-content-center'>
          <Middle style={{ height: '60vh' }}>

            {/** Access form */}
            <form className='loginForm' noValidate onSubmit={handleSubmit(sendLoginForm)}>
              <IonGrid style={{ width: '100%' }}>
                {access.formFields.map((field, index) => (
                  <IonRow key={'login-form-field-row-' + field.name} className='ion-text-left'>
                    <IonCol>
                      <Input {...field}
                        key={'login-form-input-' + index}
                        control={control}
                        errors={errors}
                      />
                    </IonCol>
                  </IonRow>
                ))}
                {
                  <IonRow>
                    <IonCol size='1'></IonCol>
                    <IonCol size='10' className='ion-text-center ion-justify-content-center'>
                      <IonSelect
                        value={lang}
                        okText={t('Okay') ?? 'Okay'}
                        cancelText={t('Dismiss') ?? 'Dismsiss'}
                        placeholder={t('Languages ') + '(' + lang + ')'}
                        style={{ color: 'black' }}
                        onIonChange={(e) => {
                          access.changeLanguage(e.detail.value)
                        }
                        }>
                        <IonSelectOption value='en'>{t('English') ?? 'English'}</IonSelectOption>
                        <IonSelectOption value='es'>{t('Español') ?? 'Español'}</IonSelectOption>
                        <IonSelectOption value='de'>{t('Deutsch') ?? 'Deutsch'}</IonSelectOption>
                        <IonSelectOption value='fr'>{t('Français') ?? 'Fraçais'}</IonSelectOption>
                      </IonSelect>
                    </IonCol>
                    <IonCol size='1'></IonCol>
                  </IonRow>
                }
                <IonRow>
                  <IonCol className='ion-text-center ion-justify-content-center'>
                    <IonButton
                      type='submit'
                      expand='block'
                      color='soft-blue'
                      style={{ margin: '4% 7% 1% 7%' }}
                      className='bold ion-text-uppercase button-inner'>
                      {sending
                        ? <IonSpinner style={{ margin: '0 0 0 3%' }} color='primary' name='dots' />
                        : t(translations.submitAccess)}
                    </IonButton>
                    <IonLabel style={{fontSize: '0.55rem'}} onClick={()=> setShowTerms(true)}>{t('By logging in you accept the')}<br/><IonLabel style={{textDecoration: 'underline'}} onClick={()=> setShowTerms(true)}>{t('Terms & Conditions and Privacy Policy')}</IonLabel></IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className='ion-text-center ion-justify-content-top'>
                    {codes.length < 1 && <NoQRCodes />}
                    {codes.length > 0 && <QRCodeList codes={codes} pageRef={pageRef} />}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </Middle>
        </ConMiddle>
        {/*<IonFab vertical='bottom' horizontal='start' slot='fixed' className='ion-padding-bottom ion-padding-end'>
          <IonFabButton color='soft-blue blink-slow' onClick={()=> setShowTerms(true)}>
            <IonIcon icon={help} />
          </IonFabButton>
        </IonFab>*/}
        <IonFab vertical='bottom' horizontal='end' slot='fixed' className='ion-padding-bottom ion-padding-end'>
          <IonFabButton color='soft-blue blink-slow' onClick={QrRead.start}>
            <IonIcon icon={qrCodeOutline} />
          </IonFabButton>
        </IonFab>
        <TermsPrivacy2 showTerms={showTerms} setShowTerms={setShowTerms} />
      </>
    {toast && <Toast {...toast} />}
  </IonPage>

}

export default React.memo(Access)

