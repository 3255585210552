import jQuery from 'jquery'

/**
 * Sets the subtitle text and applies animation to the subtitle container.
 * If the text is provided, it fades out the current subtitle, animates the container,
 * updates the subtitle text, and fades it back in.
 * If the text is not provided, it fades out the current subtitle and applies a full animation to the container.
 * 
 * @param text - The subtitle text to be displayed.
 * @param subtitleId - The ID of the subtitle element.
 * @param containerId - The ID of the container element.
 */
export const setSubtitle = (content: string | any, subtitleId: string, containerId: string) => {

  const subtitle = jQuery(subtitleId)
  const container = jQuery(containerId)
  
  const full = { fontSize: '1.3rem', padding: '4% 0', margin: '0px 2%', duration: 'fast' }
  const shaped = { fontSize: '1.1rem', padding: '0%', margin: '9px 2%', duration: 'fast' }

  if (content) {
    subtitle.fadeOut('fast', () => {
      container.animate(shaped, () => {
        subtitle.html(content).fadeIn('fast')
      })
    })
  } else {
    subtitle.fadeOut('fast')
    container.animate(full)
  }
  
}
