import i18n from '../../components/common/i18n'
import { langsPriority } from '../../env'

export const translate = (translations: any = {}, get: string): any => {

  let result = ''
  let lang = i18n.language
  if (lang === '') lang = langsPriority[0]
  if( result !== '') return result
  
  if (translations !== undefined) {

    try {

      // The selected language existent case
      for (let index = 0; index < translations.length; index++){
        if (translations[index].language.code.includes(lang)) {
          result = setReturn(translations[index], get)
        }
      }

      if(result === ''){
        // Moving by priority languages
        for (let langPrior of langsPriority) {
          for (let index = 0; index < translations.length; index++){
            if(translations[index].language.code === langPrior){
              result = setReturn(translations[index], get)
            }
          }
        }
      }

      if(result === ''){
        // Default in case of lack of translation on the desired language...
        for (let index = 0; index < translations.length; index++){
          if (translations[index].language.code.includes(langsPriority[0])) {
            result = setReturn(translations[index], get)
          }
        }
      }

    } catch (error) {
      //console.log('trans error', lang, translations, error);
    }

  }

  return result

}

export const setReturn = (translation: any, get : string) => {
  if(get==='description'){
    return translation['description']
  }else if(get==='label'){
    return translation['label']
  }else if(get==='title'){
    return translation['title']
  }else if(get==='name'){
    return translation['name']
  }else{
    return ''
  }
}