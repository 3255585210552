import React from 'react'
import { IonFab, IonFabButton, IonIcon } from '@ionic/react'
import { FavProps } from './types'

const FavBall: React.FC<FavProps> = ({ show, style, jumpToStart }) => <>
  {jumpToStart
    ? <IonFab style={{ display: show ? 'inline' : 'none' }} vertical={style.vertical} horizontal='end' slot='fixed'>
      <IonFabButton className='mapboxgl-ctrl-geolocate fade-in-5' color={style.color}>
        <IonIcon key='iconein' className={style.class} icon={style.icon} onClick={(e: any) => { jumpToStart(e) }} />
      </IonFabButton>
    </IonFab>
    : <IonFab style={{ display: show ? 'inline' : 'none' }} vertical={style.vertical} horizontal='end' slot='fixed'>
      <IonFabButton className='mapboxgl-ctrl-geolocate fade-in-5' color={style.color}>
        <IonIcon key='iconein' className={style.class} icon={style.icon} />
      </IonFabButton>
    </IonFab>
  }
</>

export default React.memo(FavBall)