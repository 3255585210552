import { ReportHandler } from 'web-vitals'

/**
 * Fué editado por David Rullán con ChatGPT para hacerlo más eficente
 * Was edited by David Rullán to make it faster
 */
const reportWebVitals = async (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    const { getCLS, getFID, getFCP, getLCP, getTTFB } = await import('web-vitals');
    const metrics = [getCLS, getFID, getFCP, getLCP, getTTFB];
    metrics.forEach(metric => {
      metric(onPerfEntry);
    });
  }
};
export default reportWebVitals
