// This way was an awsome one!
import i18n from 'i18next'
import { keyTrans } from './classes/i18nextClass'

export const all = process.env

// The default values...
// TODO: Make it come from the .env...
export const appName = "hoponboard"
export const appIcon = 'assets/icon/transp-icon-512.png'

const publicUrlDefault = 'http://localhost:3000'
const apiUrlDefault = 'https://cms.hoponboard.eu'
const nodeEnvDefault = 'development'
const mapboxKeyDefault = "pk.eyJ1IjoiZHJ1bGxhbiIsImEiOiJja2l4eDBpNWUxOTJtMnRuejE1YWYyYThzIn0.y7nuRLnfl72qFp2Rq06Wlg"
const allowedDomainsDefault = ['localhost:8100','localhost:3000']
const asciiArtDefault = "  ██░ ██ ▒█████  ▄▄▄▄      \n ▓██░ ██▒██▒  ██▓█████▄    \n ▒██▀▀██▒██░  ██▒██▒ ▄██   \n ░▓█ ░██▒██   ██▒██░█▀     \n ░▓█▒░██░ ████▓▒░▓█  ▀█▓   \n  ▒ ░░▒░░ ▒░▒░▒░░▒▓███▀▒   \n  ▒ ░▒░ ░ ░ ▒ ▒░▒░▒   ░    \n  ░  ░░ ░ ░ ░ ▒  ░    ░    \n  ░  ░  ░   ░ ░  ░         \n  ▄▄▄      ██▓███  ██▓███  \n ▒████▄   ▓██░  ██▓██░  ██▒\n ▒██  ▀█▄ ▓██░ ██▓▓██░ ██▓▒\n ░██▄▄▄▄██▒██▄█▓▒ ▒██▄█▓▒ ▒\n  ▓█   ▓██▒██▒ ░  ▒██▒ ░  ░\n  ▒▒   ▓▒█▒▓▒░ ░  ▒▓▒░ ░  ░\n   ▒   ▒▒ ░▒ ░    ░▒ ░     \n   ░   ▒  ░░      ░░       \n       ░  ░                      \n ᵦᵧ ᴅₐᵥᵢ𝓭 ᵣᵤₗₗáₙ ᴅíₐ𝆎 𝔀ᵢₜₕ ₗₒᵥₑ ;₎\n 𝖧ɑρρɣ ɕ𝗈ᑯ౿"

// The exported constants...
export const publicUrl  = 'https://app.hoponboard.eu';//process.env.REACT_APP_PUBLIC_URL ?? publicUrlDefault
export const apiUrl     = process.env.REACT_APP_API_URL ?? apiUrlDefault
export const nodeEnv    = process.env.REACT_APP_NODE_ENV ?? nodeEnvDefault
export const mapboxKey  = process.env.REACT_APP_MAPBOX_KEY ?? mapboxKeyDefault
export const allowedDomains = process.env.REACT_APP_ALLOWED_DOMAINS ? process.env.REACT_APP_ALLOWED_DOMAINS?.split('|') : allowedDomainsDefault
export const asciiArt   = process.env.REACT_APP_PROJECT_ASCIIART ?? asciiArtDefault

// new !!
export const RESET = "\x1b[0m"
export const RED = "\x1b[31m"

export const apiUploads = apiUrlDefault + '/uploads/'
export const appAssets = publicUrl + '/assets/'

// This is the list of available langs, also the prior order along app.
export const langsPriority = ['en', 'es', 'de', 'fr']

export const imgSizes = ['thumbnail', 'small', 'medium', 'large']

export const menuSettings = {
  'hiddenFooter' : [ 
      '/access',
      '/menu/home',
      '/settings',
      '/access/train-yourself',
      '/access/explore-and-equip',
      '/access/navigate',
      '/access/assistance'
  ],
  'freeAccess' : [ // This list is the areas of the app with free access
    '/menu/home',
    '/access',
    '/settings'
  ]
}

// PROVISIONAL::
export const translations = keyTrans

// Must come from assets, 'im pretty sure mmmm 

// TODO: try to verfy why it gets throw, from where and why!!!
export const appIconSplash = appAssets + 'icon_hob_f8df2ad0d3.png'
const shareIcon = apiUploads + 'IMG_BC_665869_B3_D7_1_94e9c07314.jpeg'
const addToHomeScreenIcon = apiUploads + 'Imagen_PNG_31ebb6323a.png'
const selectAddToHomeScreenIcon = apiUploads + 'instructt3_9278a46ae6.png'

export const addToHomeScreenImages = [
  {
    src: shareIcon,
    alt: i18n.t('Tap the Share Icon'),
  },
  {
    src: addToHomeScreenIcon,
    alt: i18n.t('We are adding to homescreen...'),
  },
  {
    src: selectAddToHomeScreenIcon,
    alt: i18n.t("Select 'Add to home screen' entry!"),
  },
]

// TESTING PURPOSES!!!
export const loadingTime = 400

export const homeHref = '/menu/home'
export const homeMenu = '/menu/'
export const accessHref = '/access'
export const trainYourself = 'train-yourself' // Main after home!!

export const splashScreen = {
  showDuration: 4000,
  autoHide: true
}

export const fadeVelocity = 300

// Map style and basics
export const style = {
  'map' : {
    'height': '95vh' ,
    'width': '100wh',
  },
  'routes' : {
    'height': '105vw',
    'width': '100vh',
    'scrollWheelZoom' :false,
    'zoomControl' : false,
  },
  'polyLine' : { fillColor: '#a2a2a2' },
  'polygon' : { fillColor: 'red' },
}

// TODO: verify best origin
export const placeMarkerDefault = apiUploads + 'PICTURESQUE_ICON_3_352a635ea5.svg'

export const mapStyle = 'mapbox://styles/mapbox/streets-v11?optimize=true'

export const mapAttribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors, <a href=&quot;https://creativecommons.org/licenses/by-sa/2.0/&quot;>CC-BY-SA</a>, Imagery &copy; <a href=&quot;https://www.mapbox.com/&quot;>Mapbox</a>"

export const mapTiles = {
  'basic': 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  'customized': 'https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=3Vi6kgEIpBCTF4mZBN8z',
  'mapbox': 'https://api.mapbox.com/styles/v1/drullan/ckqbpu0ia03ve17o4278zpw3f/tiles/256/{z}/{x}/{y}@2x?access_token='+mapboxKey
}

// Map initial data
export const main_zoom = 14
export const main_center : [number, number] = [ 39.795809579006914, 2.694740295410156 ]

export const slideOpts = {
  initialSlide: '0',
  speed: 500,
  autoplay: false,
  autoHeight: false,
  centeredSlides: true,
  centeredSlidesBounds: true,
  spaceBetween: 0,
  loop: false
}
