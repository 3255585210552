import axios from 'axios'
import { allowedDomains } from '../../env'
import { apiUrl } from '../../env'
import { setStorage, getStorage } from '../../data/utils/storage'
import { menusQuery } from './graphQuery'
import { getBetterImages, getBase64ByHttpUrl } from './images'

/**
 * Calcula si ha pasado suficiente tiempo desde la última recarga de un recurso.
 * @param resourceName - Nombre del recurso a recargar.
 * @param sTimeout - Tiempo de espera en segundos antes de recargar (predeterminado: 60).
 * @returns Verdadero si es el momento adecuado para recargar, falso si es demasiado pronto.
 */
export const calculateReload = async (resourceName: string, sTimeout: number = 60 ) => {
  let now = Date.now()
  let reloadMargin = sTimeout * 1000
  let storingAppData = await getStorage('storingAppData')  
  if(now < storingAppData + reloadMargin){
    console.log('Too early for reload !!', resourceName, sTimeout)
    return false
  }else{
    await setStorage(resourceName, now)
    return true
  }
}

export const storingAppData = async () => {

  if(!await calculateReload('storingAppData', 3600)) return true

  await axios.get(apiUrl + '/app-menus?main=true&_sort=order')
    .then(menus => { setStorage('mainMenu', menus.data) })

  // Storing menu children data of the menus
  await axios.post(apiUrl + '/graphql', menusQuery)
  .then(menus => {
    for (let menu of menus.data.data.appMenus) {
      setStorage('menu_' + menu.slug, menu)
    }
    setStorage('menus', menus.data.data.appMenus)
  })

  // Storing articles
  await axios.get(apiUrl + '/app-articles')
    .then(art => {
      let articles = art
      for (let article of articles.data) {
        setStorage('article_' + article.slug, article)
      }
      setStorage('articles', articles.data)
    })

  // Storing slides
  await axios.get(apiUrl + '/app-slides')
    .then(slides => {
      setStorage('slides', slides)
      for (let slide of slides.data) {
        setStorage('slide_' + slide.slug, slide)
      }
    })

  // Storing routes colours
  await axios.get(apiUrl + '/route-colors')
    .then(colors => { setStorage('colors', colors.data) })

  // Storing map markers
  await axios.get(apiUrl + '/map-markers')
    .then(markers => {
      for (let marker of markers.data) {
        if (marker.icon) {
          setStorage('marker_' + marker.id, marker)
        }
      }
      setStorage('markers', markers.data)
    })
    
  console.info('- The data storage was done...!!!')

}

export const setLogged = async (data: any) => {
  return await axios.post(apiUrl + '/success-logins', {
    email: data.email,
  }).then(res => {
    //console.log(t('You have logged in successfully'))
  })
}

export const storingClientData = async (loginData: any, sTimeout: number = 30) => {

  // Saving some data on the storage
  let creator_id = loginData.user.creator.toString()

  await setStorage('creator:id', creator_id)
    .then(async ()=>{
      setStorage('user', loginData.user)
      setStorage('jwt', loginData.jwt)
      setStorage('creator:contact_number', { phone: loginData.user.contact_number })
      setStorage('creator:assistance_number', '112')
    
      let axiosConf = {
        headers: {
          'Authorization': 'Bearer ' + loginData.jwt
        }
      }
    
      // Storing owner routes info
      await axios.get(apiUrl + '/routes?published=true&created_by=' + creator_id, axiosConf)
        .then(routes => {
          getStorage('colors')
            .then(async(colors:any) => {
              var routes_colorized = []
              let i = 0
              for (let route of routes.data) {
		if (route.map_data) {
	          route.color = selColorByIndex(colors, i)
	          routes_colorized.push(route)
                   ++i
		}
              }
              setStorage('routes_' + creator_id, routes_colorized)
              setRoutesCenter(routes_colorized)
              for (let route of routes_colorized) {
                setStorage('route_' + route.id, route)
                for (let place of route.places) {
                  // Deep recover of the place
                  axios.get(apiUrl + '/my-places/' + place.id)
                    .then(place => {
                      setStorage('place_' + place.data.id, place.data)
                    })
                    .catch((err)=>console.log('Was a problem 1....', err))
                }
              }
            })
            .catch((err)=>console.log('Was a problem 2....', err))
        })
        .catch((err: any) => console.log('Error on colors: ', err))
    
      // Storing owner boats info
      await axios.get(apiUrl + '/app-boats?created_by=' + creator_id, axiosConf)
        //await axios.post(apiUrl + '/graphql', equipmentsQuery)
        .then(res => {
          let boats = res.data
          // Set all the boats from the owner
          if(boats.length > 0){
            setStorage('boats_' + creator_id, boats)
            for (let boat of boats) {
              setStorage('boat_' + boat.id, boat)
            }
          }
        })
        .catch((err: any) => console.log('Error on boats: ' + err))
    
      await setLogged(loginData)
    
      //console.log('Done storingClientData...!!!')
      console.log("%cThe data storage was done!!!", 'color: red;');
      
    })
    .catch((err)=>console.log('Was a problem...!', err))

  return true
}

/**
 * Calculates the center of a set of routes and places, and stores the result in the storage.
 * @param routes - The routes to be used for calculating the center.
 */
export const setRoutesCenter = (routes: any) => {
  // Variables for center calculation
  let totalLat = 0; // Total sum of latitudes
  let totalLng = 0; // Total sum of longitudes
  let totalCoordinates = 0; // Total number of coordinates
  let totalPlaces = 0; // Total number of places

  // Iterate over the routes
  for (const route of routes) {
    // Get the coordinates of the route
    const coordinates = route.map_data.data.geometry.coordinates;

    // Calculate the sum of latitudes and longitudes of the coordinates
    for (const coordinate of coordinates) {
      totalLat += parseFloat(coordinate[1]);
      totalLng += parseFloat(coordinate[0]);
      totalCoordinates++;
    }

    // Get the places of the route
    const places = route.places;

    // Calculate the sum of latitudes and longitudes of the places
    for (const place of places) {
      totalLat += parseFloat(place.map_data.center_lat);
      totalLng += parseFloat(place.map_data.center_long);
      totalPlaces++;
    }
  }

  // Calculate the center coordinates
  const centerLat = (totalLat / (totalCoordinates + totalPlaces)) || 0;
  const centerLng = (totalLng / (totalCoordinates + totalPlaces)) || 0;

  // Create the return object with the center coordinates and zoom level
  const return_ = { lat: centerLat, lng: centerLng, zoom: 8 };

  // Store the center in the storage
  setStorage('center', return_);
}


export const selColorByIndex = (colors: any, index: number) => {
  let result = ''
  if(!colors) return
  for(let i = 0; i < colors.length; i++){
    if (i === index){
      result = colors[i].hex_color
      return result
    }
  }
  return result
}

export const storeOrApi = async (key: string, url: string, onSuccess: Function, onError?: Function) => {
  await getStorage(key)
    .then(res1 => {
      onSuccess(res1)
    })
    .catch(err1 => {
      axios.get(apiUrl + '/' + url)
        .then(res2 => {
          setStorage(key, res2.data[0])
          onSuccess(res2.data[0])
        }).catch((err2: any) => {
          if (onError) onError(err2)
        })
    })
}

export const getStorageAfter = async (key: string, onSuccess: Function, onError: Function, after: number = 10000) => {
  getStorage(key).then(after => {
    if (after < Date.now() + after) {
      onSuccess()
    } else {

    }
  })
    .catch(err => {
      onError()
    })
}

export const getPWADisplayMode = () => {
  return (document.referrer.startsWith('android-app://'))
    ? 'twa' : window.matchMedia('(display-mode: standalone)').matches //|| navigator.standalone
      ? 'standalone' : 'browser'
}

export const getTranslations = async () => {
  return await axios.get(apiUrl + '/translations').then(d => { return d.data })
}

export const prepareBoats = async (boats: any) => {
  const optimizedBoats = await Promise.all(boats.map(async (boat:any) => {
    if (!boat.optim) {
      const optimizedImages = await setItBe(boat.pictures)
      boat.optim = optimizedImages
      boat.pictures = null
    }
    return boat
  }))
  return optimizedBoats
}

export const preparePlace = async (place:any) =>{
  let myPlace = place
  myPlace.optim = []
  for(let i = 0; i < myPlace.images.length; i++){
    if(!myPlace.optim[i]){
      let iimm = myPlace.images[i]
      myPlace.optim[i] = await setItBe2(iimm)
    }
  }
  return myPlace
}

export const setItBe = async (images:any) => {
  let better = getBetterImages(images)
  for(let i = 0; i < better.length; i++){
    better[i].big.src = await getBase64ByHttpUrl(better[i].big) 
  }
  return better
}

export const setItBe2 = async (images:any) => {
  if(images !== undefined){
    let better = getBetterImages([images])
    better[0].big.src = await getBase64ByHttpUrl(better[0].big) 
    return better[0]
  }else{
    return []
  }
}

export const isApprovedUrl = (url: any, access: any, t: any) => {

  let path = url.replace('//', '/').split('/')
  let idx = 0

  let result = { identifier: '', password: '' }

  if (path[0] === 'http:' || path[0] === 'https:') {
    idx = 1
  }

  if (allowedDomains && allowedDomains.some((domain: any) => path[idx].includes(domain))) {
    console.log('Is an allowed domain...')
  } else {
    access.getCatch(t('The domain "{{domain}}" is not allowed!!', { domain: path[idx] } ), 'warning', 0)
    return result
  }

  let allowedUris = ['access', 'Access']
  idx++
  if (allowedUris.some((uri: any) => path[idx].includes(uri))) {
    console.log('The uri is correct')
  } else {
    access.getCatch(t('This uri is incorrect!'), 'warning', 0)
    return result
  }

  if (path[idx + 3] !== undefined) {
    access.getCatch(t('Too many parameters!'))
    return result
  }

  if (path[idx + 1] !== undefined && path[idx + 2] !== undefined) {
    console.log('We have two parameters later!!', path[idx + 1], path[idx + 2])
  } else {
    access.getCatch(t('This uri is incorrect!'))
    return result
  }

  return { identifier: path[idx + 1], password: path[idx + 2] }

}
