// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

import axios from 'axios'
import { fonts_list } from './data/static/fonts_list'
import { publicUrl, nodeEnv } from './env'

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void
  onUpdate?: (registration: ServiceWorkerRegistration) => void
}

export const getAsciiHeader = async (text: string, font: string) => {
  let url = 'https://artii.herokuapp.com/make?text=' + text + '&font=' + (font ? font : fonts_list[Math.floor(Math.random() * fonts_list.length)])
  await axios.get(url)
    .then((res: any) => console.info(res.data.text()))
    .catch((error:any) => console.error('ServiceWorker Error >_<!', error))
}

export const register = (config?: Config) => {

  if( nodeEnv === 'production' && 'serviceWorker' in navigator ) {

    // The URL constructor is available in all browsers that support SW.
    const url = new URL(publicUrl, window.location.href)

    if (url.origin !== window.location.origin) {

      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      console.info('- /!\\ Sorry dude, but our service worker won`t work this way -_-!')
      return

    }

    window.addEventListener('load', () => {

      const swUrl = `${publicUrl}/service-worker.js`

      if (isLocalhost) {

        checkValidServiceWorker(swUrl, config)

        // This is running on localhost. Let's check if a service worker still exists or not.
        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.info(
            '- Hob-app is being served, cache-first, by a service worker.' +
            '** To learn more, visit https://cra.link/PWA')
        })

      } else {

        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config)

      }

    })

  } else {

    console.info('- No serviceWorker available because:', {
      env: nodeEnv,
      availableServiceWorker: 'serviceWorker' in navigator,
      swUrl: `${publicUrl}/service-worker.js`
    })

  }

}

const registerValidSW = (swUrl: string, config?: Config) => {

  navigator.serviceWorker.register(swUrl)
    .then((registration) => {

      registration.onupdatefound = () => {

        const installingWorker = registration.installing;

        if (installingWorker == null) {
          return
        }

        installingWorker.onstatechange = () => {

          if (installingWorker.state === 'installed') {

            if (navigator.serviceWorker.controller) {

              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              output('- New content is available and will be used when all' +
                ' tabs for this page are closed. See https://cra.link/PWA.')

              // Execute callback
              if (config && config.onUpdate)
                config.onUpdate(registration)

            } else {

              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              output('- '+nodeEnv+' content is cached for offline use! ^_^.')

              // Execute callback
              if (config && config.onSuccess)
                config.onSuccess(registration)

            }

          }

        }

      }

    })
    .catch((error) => {
      output('/!\\ Error during service worker registration: ' + error)
    })

}

const checkValidServiceWorker = (swUrl: string, config?: Config) => {

  // Check if the service worker can be found. If it can't reload the page.
  let header = {
    headers: { 'Service-Worker': 'script' }
  }

  fetch(swUrl, header).then((response) => {

    // Ensure service worker exists, and that we really are getting a JS file.
    const contentType = response.headers.get('content-type')

    if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {

      // No service worker found. Probably a different app. Reload the page...
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => {
          window.location.reload()
        })
      })

    } else {

      // Service worker found. Proceed as normal.
      registerValidSW(swUrl, config)

    }

  }).catch((error:any) => {
      output('/!\\ No internet connection found! ú_ù.\n Now Hob-app is running in offline mode o.o!')
      console.info(error)
    }
  )

}

export const unregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => registration.unregister())
      .catch((error) => output(error.message))
  }
}

export const output = (err: any) => {
  console.info(err)
}