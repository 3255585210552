import React, { useEffect, useMemo, useRef, useState } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { RouteComponentProps } from 'react-router'
import Map from '../../components/extra/Map'
import FooterMenu from '../../components/common/FooterMenu'
//import TermsPrivacy from '../../components/common/TermsPrivacy'
import { MenuProps } from '../../data/models/Menu'
import { getStorage } from '../../data/utils/storage'
import Header from '../../components/common/Header'

interface PageProps extends RouteComponentProps<{
  creator: string,
  selected: string
}> { }

const Routes: React.FC<PageProps> = () => {

  const [header, setHeader] = useState<MenuProps[]>([])

  const pageRef = useRef<HTMLElement>(null);
  
  const routesObj = useMemo(()=>{

    return {

      init: ()=>{
        const ac = new AbortController()
        getStorage('menu_routes').then(setHeader)
        return () => ac.abort()
      },
      
      geolocation: false,

      mapStyle: {
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100vw',
      }
    }
    
  },[])

  useEffect(()=>{
    routesObj.init()
  },[routesObj])
  
  return <IonPage ref={pageRef} key='routesPage' >
    {header && <Header header={header as any} />}
    <IonContent>
      <Map
        style={routesObj.mapStyle}
        geolocation={routesObj.geolocation}
        urlSelected={'all'}
        showSelector={false}
        pageRef={pageRef.current}
        scroll={true}
      />
    </IonContent>
    <FooterMenu index={1} />
  </IonPage>

}

export default React.memo(Routes)