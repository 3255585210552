import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { asciiArt, all, nodeEnv } from './env'

//import dotenv from 'dotenv';
//dotenv.config();

const container = document.getElementById('root')

const root = createRoot(container!)

const AppWithCallbackAfterRender = () => {

  useEffect(() => {
    // First and last run of the asciiart
    console.info(asciiArt)
    // development TODO comment os block somway
    if(nodeEnv === 'development'){
      console.log(all)
    }else{
      console.log('- Production environment ;)')
    }
  })

  return <React.StrictMode>
    <App />
  </React.StrictMode>

}

root.render(<AppWithCallbackAfterRender />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()