export const accessData = {
  id: 56,
  name: 'Access',
  ionic_resource: 'Menu',
  main: false,
  description: null,
  background_color: null,
  slug: 'access',
  ionic_icon: null,
  style: null,
  order: null,
  app_slide: null,
  parent_menu: null,
  slide_step: null,
  href: null,
  show_header: true,
  show_back: false,
  lang: 'es',
  label: [
    {
      id: 211,
      label: 'WELCOME TO HOPONBOARD',
      language: {
        id: 1,
        name: 'English',
        code: 'en-GB',
      },
      placeholder: null
    },
    {
      id: 212,
      label: 'BIENVENIDO A HOPONBOARD',
      language: {
        id: 2,
        name: 'Spanish',
        code: 'es-ES',
      },
      placeholder: null
    }
  ],
  children: [],
  icon: {
    id: 387,
    name: 'icon_hob.png',
    alternativeText: '',
    caption: '',
    width: 1025,
    height: 1025,

    hash: 'icon_hob_f8df2ad0d3',
    ext: '.png',
    mime: 'image/png',
    size: 120.48,
    url: '/uploads/icon_hob_f8df2ad0d3.png',
    previewUrl: null,
    provider: 'local',
    provider_metadata: null
  },
  icon_inactive: {
    id: 388,
    name: 'splash screen.png',
    alternativeText: '',
    caption: '',
    width: 4097,
    height: 4096,
    hash: 'splash_screen_efc691afe4',
    ext: '.png',
    mime: 'image/png',
    size: 238.41,
    url: '/uploads/splash_screen_efc691afe4.png',
    previewUrl: null,
    provider: 'local',
    provider_metadata: null,
  }
}