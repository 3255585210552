import React, { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { IonPage, IonContent } from '@ionic/react'
import jQuery from 'jquery'

import { getStorage } from '../../data/utils/storage'
import { MenuProps } from '../../data/models/Menu'

import { addToHomeScreenSettings } from '../../data/static/addToHomeScreen'
import AddToHomeScreen from '@ideasio/add-to-homescreen-react'

import Header from '../../components/common/Header'
import Submenu from '../../components/common/Submenu'
import FooterMenu from '../../components/common/FooterMenu'
import { accessHref } from '../../env'
//import TermsPrivacy from '../../components/common/TermsPrivacy'

interface MenuRouting
  extends RouteComponentProps<{
    slug: string
  }> { }

const MyMenu: React.FC<MenuRouting> = ({ match }) => {

  //const { t } = useTranslation()
  const history = useHistory()
  const [page, setPage] = useState()
  const [style, setStyle] = useState('')
  const [header, setHeader] = useState<MenuProps[]>([])
  const [submenu, setSubmenu] = useState<MenuProps[]>([])

  let menuObj = useMemo(() => {

    return {

      load: () => {
        const ac = new AbortController()
        getStorage('menu_' + match.params.slug)
          .then(menuObj.onInitSuccess)
          .catch(menuObj.onInitError)
        return () => ac.abort()
      },

      onInitSuccess: (menu: any) => {
        setPage(menu.slug)
        setHeader(menu)
        const sub = menu.children[0].menus.sort(menuObj.getSort('order'))
        setSubmenu(sub)
        menuObj.setScroll(menu.children[0].menus)
      },

      onInitError: () => {
        history.replace(accessHref)
      },

      setScroll: (menus: any) => {
        setTimeout(() => {
          const pageHeight = jQuery('#menu-page').height() ?? 0
          const showScroll = menus.length > 4 && pageHeight < 700
          setStyle(showScroll ? '' : 'no-scroll')
        }, 500)
      },

      getSort: (index: any) => {
        return (a: any, b: any) => {
          if (a[index] > b[index]) {
            return 1
          } else if (a[index] < b[index]) {
            return -1
          }
          return 0
        }
      }

    }

  }, [history, match])

  useEffect(() => {
    menuObj.load()
  }, [match, menuObj])

  return <IonPage key={page} id='menu-page' className='no-scroll'>
    <AddToHomeScreen {...addToHomeScreenSettings} />
    {/*<TermsPrivacy />*/}
    {header && <Header header={header as any} />}
    <IonContent id='menu-content' className={style}>
      <Submenu submenu={submenu} />
    </IonContent>
    <FooterMenu path={history.location.pathname} />
  </IonPage>

}

export default React.memo(MyMenu)