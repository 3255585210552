export const menusQuery = {
  query: `
    query AppMenus {
      appMenus {
        id
        order
        name
        ionic_resource
        main
        slug
        show_header
        show_back
        label {
          id
          label
          language {
            id
            name
            code
          }
        }
        icon {
          url
        }
        icon_inactive {
          url
        }
        children {
          id
          menus {
            id
            order
            name
            ionic_resource
            main
            slug
            show_header
            show_back
            label {
              id
              label
              language {
                id
                name
                code
              }
            }
            icon {
              url
            }
            icon_inactive {
              url
            }
          }
        }
      }
    }
  `
}

export const equipmentsQuery = {
  query: `
    query  {
      boats {
        id
        name
        max_capacity
        type_model
        length_boat
        horse_power
        boat_equipment {
          id
          app_equipment {
            id
            name
            type
            caret {
              url
            }
          }
          equipment_type {
            name
          }
        }
      }
    }
  `
}