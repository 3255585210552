import { apiUrl, imgSizes } from '../../env'
/**
 * Calculates the size factor based on the dimensions of an image.
 * @param image Object representing the image.
 * @returns The calculated size factor.
 */
export const calculateSizeFactor = (image: any) => {
  if (image.size > 300) {
    return 0.4
  } else if (image.size > 200) {
    return 0.4
  } else if (image.size > 100) {
    return 0.5
  } else {
    return 0.7
  }
}

/**
 * Retrieves the base64 representation of an image using an HTTP URL.
 * @param image Object representing the image.
 * @returns A promise that resolves with the base64 representation of the image.
 */
export const getBase64ByHttpUrl = async (image: any) => {
  let quality = calculateSizeFactor(image)
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.setAttribute('crossOrigin', 'anonymous')
    img.onload = () => {
      let canvas = document.createElement("canvas")
      canvas.width = image.width
      canvas.height = image.height
      let ctx = canvas.getContext("2d")
      if (!ctx || !(ctx instanceof CanvasRenderingContext2D)) {
        throw new Error('Failed to get 2D context')
      }
      ctx.drawImage(img, 0, 0)
      resolve(canvas.toDataURL(image.mime, quality))
    }
    img.onerror = (err) => {
      reject(err)
    }
    img.src = apiUrl + image.url
  })
}

/**
 * Retrieves the best images in different formats (small and big) from an array of images.
 * @param images Array of images.
 * @param maxFormat The maximum format for the big image (default: 'mediumBueno').
 * @param minFormat The minimum format for the small image (default: 'small').
 * @returns An array of objects containing the small and big images.
 */
export const getBetterImages = (images: any[], maxFormat: string = 'mediumBueno', minFormat: string = 'small') => {
  return images.map((image) => ({
    small: getMin(image, minFormat),
    big: getMax(image, maxFormat)
  }))
}

/**
 * Retrieves the minimum format of an image based on a specific size.
 * @param image Object representing the image.
 * @param minSize The desired minimum size (default: 'small').
 * @returns The image in the found minimum format or the original image if no format is found.
 */
export const getMin = (image: any, minSize: string = 'small') => {
  const formats = image.formats
  if (formats && formats !== null) {
    if (formats[minSize]) {
      return formats[minSize]
    }
    for (const format of imgSizes) {
      if (formats[format]) {
        return formats[format]
      }
    }
  }
  return image
}

/**
 * Retrieves the maximum format of an image based on a specific size.
 * @param image Object representing the image.
 * @param maxSize The desired maximum size (default: 'large').
 * @returns The image in the found maximum format or the original image if no format is found.
 */
export const getMax = (image: any, maxSize: string = 'large') => {
  const { formats } = image
  if (formats && formats !== null) {
    if (formats[maxSize]) {
      return formats[maxSize]
    }
    for (const format of imgSizes.reverse()) {
      if (formats[format]) {
        return formats[format]
      }
    }
  }
  return image
}

/**
 * Retrieves the base64 representation of an image.
 * @param originalImage Object representing the image.
 * @returns A promise that resolves with the base64 representation of the image.
 */
export const getBase64Image = async (originalImage: any) => {
  const img = new Image()
  img.crossOrigin = 'anonymous'
  return new Promise((resolve, reject) => {
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      if (!ctx || !(ctx instanceof CanvasRenderingContext2D)) {
        reject(new Error('Failed to get 2D context'))
        return
      }
      ctx.drawImage(img, 0, 0)
      resolve(canvas.toDataURL(originalImage.mime).replace(/^data:image\/(png|jpg)base64,/, ''))
    }
    img.onerror = (err) => {
      reject(err)
    }
    img.src = apiUrl + originalImage.url
  })
}
